<template>
    <div>404找不到页面</div>
</template>
<script>
export default {
    data() {
        return{
            
        }
    },
}
</script>